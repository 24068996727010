import revive_payload_client_4sVQNw7RlN from "/home/runner/work/AeroEstate/AeroEstate/nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/AeroEstate/AeroEstate/nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/AeroEstate/AeroEstate/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/AeroEstate/AeroEstate/nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/AeroEstate/AeroEstate/nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/AeroEstate/AeroEstate/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/AeroEstate/AeroEstate/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_dac0So5JJ9 from "/home/runner/work/AeroEstate/AeroEstate/nuxt/node_modules/@kevinmarrec/nuxt-pwa/dist/runtime/workbox/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/AeroEstate/AeroEstate/nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import start_tUwnkHRHuG from "/home/runner/work/AeroEstate/AeroEstate/nuxt/plugins/start.js";
import vue_toast_dN21YhgRRj from "/home/runner/work/AeroEstate/AeroEstate/nuxt/plugins/vue-toast.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_dac0So5JJ9,
  chunk_reload_client_UciE0i6zes,
  start_tUwnkHRHuG,
  vue_toast_dN21YhgRRj
]