import { default as _91_91id_93_93f65NOxHewDMeta } from "/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/cart/[[id]].vue?macro=true";
import { default as indexzj89sQHjJ5Meta } from "/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/contact/index.vue?macro=true";
import { default as _91id_93WoWQfLhJhPMeta } from "/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/contract/[id].vue?macro=true";
import { default as index2EMxRBfFAOMeta } from "/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/hanger/index.vue?macro=true";
import { default as indexR0tPL9ZgUZMeta } from "/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/house/index.vue?macro=true";
import { default as indexV8LKYHHYqsMeta } from "/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/index.vue?macro=true";
import { default as index1KZbZoGaZUMeta } from "/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/services/index.vue?macro=true";
import { default as indexKpL2nhAkqbMeta } from "/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/shops/index.vue?macro=true";
import { default as indexaYOxocoZnhMeta } from "/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/stands/index.vue?macro=true";
import { default as LogIn53cd9gIuZpMeta } from "/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/User/LogIn.vue?macro=true";
import { default as profilew0GvNyv3NBMeta } from "/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/User/profile.vue?macro=true";
import { default as RegisterHaXVfByMI7Meta } from "/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/User/Register.vue?macro=true";
export default [
  {
    name: _91_91id_93_93f65NOxHewDMeta?.name ?? "cart-id",
    path: _91_91id_93_93f65NOxHewDMeta?.path ?? "/cart/:id?",
    meta: _91_91id_93_93f65NOxHewDMeta || {},
    alias: _91_91id_93_93f65NOxHewDMeta?.alias || [],
    redirect: _91_91id_93_93f65NOxHewDMeta?.redirect,
    component: () => import("/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/cart/[[id]].vue").then(m => m.default || m)
  },
  {
    name: indexzj89sQHjJ5Meta?.name ?? "contact",
    path: indexzj89sQHjJ5Meta?.path ?? "/contact",
    meta: indexzj89sQHjJ5Meta || {},
    alias: indexzj89sQHjJ5Meta?.alias || [],
    redirect: indexzj89sQHjJ5Meta?.redirect,
    component: () => import("/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WoWQfLhJhPMeta?.name ?? "contract-id",
    path: _91id_93WoWQfLhJhPMeta?.path ?? "/contract/:id()",
    meta: _91id_93WoWQfLhJhPMeta || {},
    alias: _91id_93WoWQfLhJhPMeta?.alias || [],
    redirect: _91id_93WoWQfLhJhPMeta?.redirect,
    component: () => import("/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/contract/[id].vue").then(m => m.default || m)
  },
  {
    name: index2EMxRBfFAOMeta?.name ?? "hanger",
    path: index2EMxRBfFAOMeta?.path ?? "/hanger",
    meta: index2EMxRBfFAOMeta || {},
    alias: index2EMxRBfFAOMeta?.alias || [],
    redirect: index2EMxRBfFAOMeta?.redirect,
    component: () => import("/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/hanger/index.vue").then(m => m.default || m)
  },
  {
    name: indexR0tPL9ZgUZMeta?.name ?? "house",
    path: indexR0tPL9ZgUZMeta?.path ?? "/house",
    meta: indexR0tPL9ZgUZMeta || {},
    alias: indexR0tPL9ZgUZMeta?.alias || [],
    redirect: indexR0tPL9ZgUZMeta?.redirect,
    component: () => import("/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/house/index.vue").then(m => m.default || m)
  },
  {
    name: indexV8LKYHHYqsMeta?.name ?? "index",
    path: indexV8LKYHHYqsMeta?.path ?? "/",
    meta: indexV8LKYHHYqsMeta || {},
    alias: indexV8LKYHHYqsMeta?.alias || [],
    redirect: indexV8LKYHHYqsMeta?.redirect,
    component: () => import("/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index1KZbZoGaZUMeta?.name ?? "services",
    path: index1KZbZoGaZUMeta?.path ?? "/services",
    meta: index1KZbZoGaZUMeta || {},
    alias: index1KZbZoGaZUMeta?.alias || [],
    redirect: index1KZbZoGaZUMeta?.redirect,
    component: () => import("/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: indexKpL2nhAkqbMeta?.name ?? "shops",
    path: indexKpL2nhAkqbMeta?.path ?? "/shops",
    meta: indexKpL2nhAkqbMeta || {},
    alias: indexKpL2nhAkqbMeta?.alias || [],
    redirect: indexKpL2nhAkqbMeta?.redirect,
    component: () => import("/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/shops/index.vue").then(m => m.default || m)
  },
  {
    name: indexaYOxocoZnhMeta?.name ?? "stands",
    path: indexaYOxocoZnhMeta?.path ?? "/stands",
    meta: indexaYOxocoZnhMeta || {},
    alias: indexaYOxocoZnhMeta?.alias || [],
    redirect: indexaYOxocoZnhMeta?.redirect,
    component: () => import("/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/stands/index.vue").then(m => m.default || m)
  },
  {
    name: LogIn53cd9gIuZpMeta?.name ?? "User-LogIn",
    path: LogIn53cd9gIuZpMeta?.path ?? "/User/LogIn",
    meta: LogIn53cd9gIuZpMeta || {},
    alias: LogIn53cd9gIuZpMeta?.alias || [],
    redirect: LogIn53cd9gIuZpMeta?.redirect,
    component: () => import("/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/User/LogIn.vue").then(m => m.default || m)
  },
  {
    name: profilew0GvNyv3NBMeta?.name ?? "User-profile",
    path: profilew0GvNyv3NBMeta?.path ?? "/User/profile",
    meta: profilew0GvNyv3NBMeta || {},
    alias: profilew0GvNyv3NBMeta?.alias || [],
    redirect: profilew0GvNyv3NBMeta?.redirect,
    component: () => import("/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/User/profile.vue").then(m => m.default || m)
  },
  {
    name: RegisterHaXVfByMI7Meta?.name ?? "User-Register",
    path: RegisterHaXVfByMI7Meta?.path ?? "/User/Register",
    meta: RegisterHaXVfByMI7Meta || {},
    alias: RegisterHaXVfByMI7Meta?.alias || [],
    redirect: RegisterHaXVfByMI7Meta?.redirect,
    component: () => import("/home/runner/work/AeroEstate/AeroEstate/nuxt/pages/User/Register.vue").then(m => m.default || m)
  }
]