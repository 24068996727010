<template>
  <NuxtLayout>
    <NuxtPage/>
  </NuxtLayout>
  <div class="floatScrollToTop" @click="scrollToTop()">
    <span class="material-icons text-success" @click="scrollToTop()">
      keyboard_double_arrow_up
    </span>
  </div>
</template>

<style>
.page-left-enter-active,
.page-right-enter-active,
.page-left-leave-active,
.page-right-leave-active {
  position: fixed;
  left: 0;
  right: 0;
  transition: all 150ms linear;
}

.page-left-enter-from, .page-right-leave-to {
  transform: translateX(100%);
}

.page-left-leave-to, .page-right-enter-from {
  transform: translateX(-100%);
}

.page-left-enter-to, .page-right-enter-to {
  transform: translateX(0);
}

.floatScrollToTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  background-color: white;
  /* Additional styles for better appearance */
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
}

</style>

<script setup>

// Add this code in your main JavaScript file
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', (event) => {
    if (event.data.type === 'SKIP_WAITING') {
      // Perform a full page refresh to load the new version
      alert('new version downloaded')
      console.log('new version downloaded')
      window.location.reload();
    }
  });
}

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}
</script>