export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"mobile-web-app-capable","content":"yes"},{"hid":"apple-mobile-web-app-title","name":"apple-mobile-web-app-title","content":"aeroestate"},{"name":"theme-color","content":"#000000"},{"property":"og:type","content":"website"},{"property":"og:title","content":"aeroestate"},{"property":"og:site_name","content":"aeroestate"}],"link":[{"rel":"stylesheet","href":"https://fonts.googleapis.com/icon?family=Material+Icons"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"},{"rel":"icon","href":"/favicon.ico","type":"image/x-icon"},{"rel":"shortcut icon","href":"icons/pwa-64x64.png"},{"rel":"apple-touch-icon","href":"icons/pwa-512x512.png","sizes":"512x512"},{"rel":"manifest","href":"/manifest.json"}],"style":[],"script":[{"src":"bootstrap/dist/js/bootstrap.min.js"},{"src":"https://player.vimeo.com/api/player.js"}],"noscript":[],"title":"aeroestate","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false