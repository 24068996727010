export default defineNuxtRouteMiddleware(async (to, from) => {
  const runtimeConfig = useRuntimeConfig();
  let title: string;

  if (to.meta.hasOwnProperty("title")) {
    runtimeConfig.public.title = String(to.meta.title);
    title = `${to.meta.title} | ${runtimeConfig.public.appName}`;
  } else {
    title = runtimeConfig.public.appName;
  }

  const getDepth = (path: string) => {
    return path.split("/").filter((seg) => seg.length > 0).length;
  };

  const toDepth = getDepth(to.path);
  const fromDepth = getDepth(from.path);

  if (toDepth > fromDepth) {
    to.meta.pageTransition = { name: "page-left" };
    from.meta.pageTransition = { name: "page-left" };
  } else {
    to.meta.pageTransition = { name: "page-right" };
    from.meta.pageTransition = { name: "page-right" };
  }

  runtimeConfig.public.auth_token = localStorage.getItem("auth_token") || "";
  runtimeConfig.public.isAuthenticated = localStorage.getItem("auth_token")
    ? true
    : false;

  useHead({
    title: title,
  });

  if (to.meta.hasOwnProperty("auth")) {
    //unprotected view
    if (to.meta.auth === false) {
      // do nothing
    }
    if (to.meta.auth === true) {
      if (!runtimeConfig.public.isAuthenticated) {
        // alert("You are not authorized to view this page")
        return navigateTo("/user/login");
      }
    }
  } else {
    //protected view
    if (!runtimeConfig.public.isAuthenticated) {
      // alert("You are not authorized to view this page")
      return navigateTo("/user/login");
    }
  }
  // isAuthenticated() is an example method verifying if a user is authenticated
  // if (isAuthenticated() === false) {
  //   return navigateTo('/login')
  // }
});
